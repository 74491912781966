import React, { Component } from 'react';
import {useEffect, useState} from 'react';


function MULTIPage()  {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    return (
        <div>
          <h2>Coming soon!</h2>
        </div>
    );
}

export default MULTIPage;
